/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { Section } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { Overlay } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { SectionHeading } from "../../components/elements"

const Stats = () => {
  const data = useStaticQuery(graphql`
    {
      datoCmsAbout {
        stats {
          id
          number
          text
        }
      }
    }
  `)
  return (
    <Section sx={{ bg: "gray.8", color: "white", position: "relative" }}>
      <Container sx={{ px: "1em", py: 4, position: "relative", zIndex: 10 }}>
        <Flex sx={{ flexWrap: "wrap" }}>
          <Box sx={{ width: ["full"], textAlign: "center" }}>
            <SectionHeading>2024 Quick Stats</SectionHeading>
          </Box>
          {data.datoCmsAbout.stats.map(({ ...stats }) => (
            <Box
              key={stats.id}
              sx={{
                borderRightColor: ["transparent", "white"],
                borderRightStyle: ["none", "solid"],
                borderRightWidth: ["none", "1px"],
                borderBottomColor: ["white", "transparent"],
                borderBottomStyle: ["solid", "none"],
                borderBottomWidth: ["1px", "none"],
                pb: [2, 2, 0],
                textAlign: "center",
                width: ["full", "1/2", "1/4"],
                "&:last-of-type": {
                  borderRightColor: "transparent",
                  borderBottomColor: "transparent",
                },
                "&:nth-child(3)": {
                  borderRightColor: ["transparent", "transparent", "white"],
                },
              }}
            >
              <Styled.h3 sx={{ fontSize: [5, 5, 6] }}>{stats.number}</Styled.h3>
              <Styled.p sx={{ m: 0 }}>{stats.text}</Styled.p>
            </Box>
          ))}
        </Flex>
      </Container>
      <Overlay sx={{ opacity: 1 }} />
    </Section>
  )
}

export default Stats
